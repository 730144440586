import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const Faq = ({ faq }) => (
  <div>
    {faq.map((faq) => (
      <div className="content">
      <h3>{faq.question}</h3>
      <article key={v4()} className="message">
        <div className="message-body">
          <cite> → {faq.answer}</cite>
        </div>
      </article>
      </div>
    ))}
  </div>
);

Faq.propTypes = {
  faq: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
};

export default Faq;